import Vue from 'vue'
import App from './App.vue'
import VueGtm from '@gtm-support/vue2-gtm';
//import VueGtag from 'vue-gtag'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faExpand, faPause, faPlay, faVolumeXmark, faVolumeHigh } from '@fortawesome/free-solid-svg-icons'

Vue.config.productionTip = false

Vue.use(VueMeta);

Vue.use(VueGtm, {
  id: 'GTM-W8GV88',
  queryParams: {
  },
  compatibility: false, 
  nonce: '7S00aPbloS', 
  vueRouter: router, 
});


library.add(faPause)
library.add(faPlay)
library.add(faVolumeXmark)
library.add(faVolumeHigh)
library.add(faExpand)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
